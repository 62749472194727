import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"

import Layout from "../../components/Layout"
import { StickyHelp } from "../../components/UI/StickyHelp"
import Posts from "../../components/AcfPostBlocks/Posts"
import { LinkButton } from "../../components/LinkButton"

import Header from "./Header"
import CategoryButtons from "./CategoryButtons"
import style from "./news.module.scss"
import { Container } from "react-bootstrap"

const News = ({ location, data, pageContext }) => {
  const { sectionHeader, description, backgroundImage } =
    data?.wpgraphql.page?.flexLayouts.flexibleLayouts[0] || {}

  const {
    posts,
    page,
    pathBase,
    nextPagePath,
    totalPosts,
    allPosts,
    postsPerPage,
    selectedCategory,
    categories,
    canonicalPath,
  } = pageContext

  let seoComponent = { seoTitle: `News | ${selectedCategory}` }

  const [postsToShow, setPostsToShow] = useState(posts)
  const [loadMoreEnable, setLoadMoreEnable] = useState(
    page === 1 && postsPerPage < totalPosts
  )

  const loadMorePosts = event => {
    event.preventDefault()
    const size = postsToShow.length + postsPerPage
    setPostsToShow([...allPosts.slice(0, size)])
    setLoadMoreEnable(page === 1 && size < totalPosts)
  }

  return (
    <Layout
      seoComponent={seoComponent}
      location={location}
      canonicalPath={canonicalPath}
    >
      <main className="position-relative pt-5">
        <div id="news-page-header ">
          <Header sectionHeader={sectionHeader} description={description} />
        </div>

        <div className="bg-light py-5">
          {pathBase !== "/vacancies" && (
            <Container>
              <CategoryButtons
                categories={categories}
                selectedCategory={selectedCategory}
              />
            </Container>
          )}
          <Posts posts={postsToShow} origin={pathBase} />
          {loadMoreEnable && page === 1 && (
            <div className="d-flex flex-column justify-content-center align-items-center pt-5 pb-6">
              <a
                className={style.loadMoreBtn}
                onClick={loadMorePosts}
                href={nextPagePath}
              >
                Load More
              </a>
            </div>
          )}
          {page !== 1 && nextPagePath && (
            <div className="d-flex justify-content-center pt-5 pb-6">
              <a className={style.loadMoreBtn} href={nextPagePath}>
                Load More
              </a>
            </div>
          )}
        </div>
        <StickyHelp />
      </main>
    </Layout>
  )
}

export default News

export const pageQuery = graphql`
  query GET_POST_LIST($pageID: ID!) {
    wpgraphql {
      page(id: $pageID) {
        title
        uri
        date
        flexLayouts {
          flexibleLayouts {
            __typename
            ... on WPGraphQL_Page_Flexlayouts_FlexibleLayouts_ListingNewsPage {
              description
              sectionHeader
              buttonText
              backgroundImage {
                ...GatsbyImageQuery
              }
              includeCategories
            }
          }
        }
        seo {
          seoComponents {
            __typename
            ...metadataSectionFragment
          }
        }
      }
    }
  }
`
