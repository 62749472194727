import React from "react"
import style from "./Header.module.scss"

const Header = ({ className, sectionHeader, description }) => {
  const classes = `header-component${className && ` ${className}`}`

  return (
    <div className={classes}>
      <h1 className="pt-2">{sectionHeader}</h1>
      <div
        className={`h4 mt-4 mb-5 text-center font-weight-normal ${style.description}`}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}

export default Header
