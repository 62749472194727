import React from "react"
import { Row, Col } from "react-bootstrap"
import style from "./Posts.module.scss"
import { createLink } from "../../utils"
import { PostCard } from "../UI/LatestPosts"

const Posts = ({ posts, origin }) => {
  if (posts.length) {
    return (
      <Row className={`pt-1 ${style.row}`}>
        {posts.map(post => {
          let mainImage = post.postsLayouts.postObject[0].mainImage
          let dt = new Date(post.date).toLocaleDateString("eng-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
          return (
            <Col
              key={post.id}
              className="mb-4 d-flex mx-auto"
              sm={12}
              md={6}
              lg={4}
            >
              <PostCard
                title={post.title}
                href={createLink(`${origin}/${post.slug}`)}
                dt={dt}
                mainImage={mainImage}
                slug={post.slug}
              />
            </Col>
          )
        })}
      </Row>
    )
  } else {
    return <div className={style.noPostsReturned} />
  }
}
export default Posts
