import { Link } from "gatsby"
import React from "react"
import { Row, Col } from "react-bootstrap"
import he from 'he';
import { createLink } from "../../utils"
import style from "./CategoryButtons.module.scss"

const CategoryButtons = ({ categories, selectedCategory }) => {

  return (
    <Row className={`${style.text} bg-white row py-4`}>
      <Col md={2} className="p-0">
        <p className={style.filterTitleCls}>Filter News:</p>
      </Col>
      <Col>
        <Row>
          <Link
            to={createLink("/news#news-filters")}
            className={`${style.tagButton} m-1 ${
              selectedCategory === "All" ? style.selectedBgCls : ""
            }`}
          >
            <div
              className={
                selectedCategory === "All"
                  ? style.tagButtonOvalSelected
                  : style.tagButtonOval
              }
            ></div>{" "}
            <span className="p-2">All</span>
          </Link>
          {categories.map((category, i) => (
            <Link
              key={i}
              index={category.name}
              to={createLink(`/news/${category.slug}#news-filters`)}
              className={`${style.tagButton} m-1 ${
                selectedCategory === category.name ? style.selectedBgCls : ""
              }`}
            >
              <div
                className={
                  selectedCategory === category.name
                    ? style.tagButtonOvalSelected
                    : style.tagButtonOval
                }
              />{" "}
              <span className="p-2">{he.decode(category.name)}</span>
            </Link>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default CategoryButtons
